import './login.css'
import { getAuth, signInWithRedirect, GoogleAuthProvider } from "firebase/auth";
import { useAuth } from "../../firebase/firebase";
import { Navigate, useLocation } from "react-router";
import {useEffect, useState} from "react";

const Login = () => {

    const [loading, setLoading] = useState(true);

    const fireBaseAuth = getAuth();

    const provider = new GoogleAuthProvider();
    const auth = useAuth();
    const location = useLocation();
    const state = location.state;
    let from = state?.from?.pathname || "/";

    useEffect(() => {
        setLoading(false);
    } , []);

    if (auth?.user) {
        return <Navigate to={from} replace/>
    }

    const signIn = () => {
        setLoading(true);
        signInWithRedirect(fireBaseAuth, provider).then(() => {
        }).catch(error => {
            console.log(error);
        });
    }

    if (loading) { return (
        <div className="grid place-items-center h-screen font-body">
            <div className="text-white font-bold bg-secondary-grey2 border-secondary-grey2 border-secondary-grey2 rounded m-5 text-center w-80 outline outline-primary-orange">
                <div className="m-6">Loading...</div>
            </div>
        </div>
    ) }

    return (

        <div className="grid place-items-center h-screen font-body">
            <div className="text-white font-bold bg-secondary-grey2 border-secondary-grey2 border-secondary-grey2 rounded m-5 text-center w-80 outline outline-primary-orange">
                <div className="m-6">You must sign in to access this app</div>
                <button onClick={() => signIn()}
                        className="bg-gray-500 hover:bg-secondary-orange3 text-white font-bold py-2 px-4 border-b-4 border-primary-orange rounded m-5">
                    Sign In
                </button>
            </div>
        </div>
    )
}

export default Login;