import './list.css';
import {getAuth, signOut} from "firebase/auth";
import {useEffect, useState} from "react";
import TagsInput from "react-tagsinput";
import Autosuggest from 'react-autosuggest'
import { CSVLink } from "react-csv";
import { searchCreators } from "../../creators/creators";
import {getTags} from "../../tags/tags";


const fireBaseAuth = getAuth();

const List = () => {

    const [creators, setCreators] = useState([]);
    const [tags, setTags] = useState([]);
    const [searchTags, setSearchTags] = useState([]);
    const [searching, setSearching] = useState(false);

    useEffect(() => {
        fireBaseAuth.currentUser.getIdToken().then(token => {
            getTags(token).then(tagRes => {
                if (tagRes.tagnames) {setTags(tagRes.tagnames)}
            });
        });
    }, [creators]);

    const search = () => {
        setSearching(true);
        fireBaseAuth.currentUser.getIdToken().then(token => {
            searchCreators(searchTags, token).then(creatorsRes => {
                if (creatorsRes.creators) {setCreators(creatorsRes.creators)}
                setSearching(false);
            });
            }
        )
    }

    const focusSearchTags = () => {
        document.getElementById('search-tags').focus();
    }

    function autocompleteRenderInput ({addTag, ...props}) {
        const handleOnChange = (e, {newValue, method}) => {
            if (method === 'enter') {
                e.preventDefault()
            } else {
                props.onChange(e)
            }
        }

        const inputValue = (props.value && props.value.trim().toLowerCase()) || ''
        const inputLength = inputValue.length

        let suggestions = tags.filter((tag) => {
            return tag.toLowerCase().slice(0, inputLength) === inputValue
        })

        return (
            <Autosuggest className="inline-flex items-center text-sm rounded mt-2 mr-1 overflow-hidden w-auto"
                         ref={props.ref}
                         suggestions={suggestions}
                         shouldRenderSuggestions={(value) => value && value.trim().length > 0}
                         getSuggestionValue={(suggestion) => suggestion}
                         renderSuggestion={(suggestion) => <span className="bg-white-100">{suggestion}</span>}
                         inputProps={{...props, onChange: handleOnChange}}
                         onSuggestionSelected={(e, {suggestion}) => {
                             addTag(suggestion)
                         }}
                         onSuggestionsClearRequested={() => {}}
                         onSuggestionsFetchRequested={() => {}}
            />
        )
    }

    const columnHeadersFieldMap = [
        ["Creator name (online handle)", "creatorname"],
        ["Creator location", "creatorlocation"],
        ["Direct or agency", "directoragency"],
        ["Youtube", "youtube"],
        ["VR30", "vr30"],
        ["Twitch", "twitch"],
        ["Avg. concurrents", "avgconcurrents"],
        ["Twitter", "twitter"],
        ["(Twitter) Followers", "twitterfollowers"],
        ["Instagram", "instagram"],
        ["(Instagram) Followers", "instagramfollowers"],
        ["TikTok", "tiktok"],
        ["(TikTok) Followers", "tiktokfollowers"],
        ["Product tags", "producttags"],
        ["Preferred genre tag", "preferredgenretag"],
        ["Potential campaign reference", "relevantcampaignreferences"],
        ["Creator group tag", "creatorgrouptag"],
        ["Red flags", "redflags"],
    ]

    const renderCreatorColunmField = (creator, header) => {

        let field;

        if (typeof creator[header] === "string") {
            field = creator[header]
        } else if (typeof creator[header] === "object") {
            field = <TagsInput className="w-full text-white h-10" readOnly={true} inputProps={{placeholder: '', className: 'react-tagsinput-input', readOnly: true}} value={creator[header].filter((item => {return item !== ""}))}/>
        }

        return (
            <td className="px-6 py-3 text-white overflow-hidden w-full flex whitespace-nowrap overflow-x-scroll no-scrollbar">
                {field}
            </td>
        )
    }

    return (
        <div className="App font-body">
            <div className="relative overflow-x-auto sm:rounded-lg px-10">
                <div className="p-4 grid grid-cols-12 gap-4 items-center w-full">
                    <label htmlFor="table-search" className="sr-only">Search</label>
                    <div className="col-start-1 relative mt-1 col-span-4" onClick={focusSearchTags}>
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg className="w-5 h-5 text-white" fill="currentColor"
                                 viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                      clip-rule="evenodd"></path>
                            </svg>
                        </div>
                        <TagsInput className="block w-full pl-10 p-2.5 bg-secondary-grey3 border-2 rounded-md border-primary-orange placeholder-white
                                    text-white"
                                   value={searchTags} renderInput={autocompleteRenderInput} onlyUnique onChange={setSearchTags}
                                   inputProps={{placeholder: 'Filter...', className: 'react-tagsinput-input', id: "search-tags"}}/>
                    </div>
                    <button onClick={search}
                        className={"col-start-5 bg-secondary-grey3 hover:bg-secondary-orange3 text-white font-bold py-2 px-4 border-b-4 border-primary-orange hover:border-secondary-orange2 rounded" + (searching ? ' animate-pulse' : '')}>
                        Search
                    </button>
                    <button onClick={() => {signOut(fireBaseAuth)}}
                            className="col-end-13 mx-auto w-28 bg-secondary-grey3 hover:bg-secondary-orange3 text-white font-bold py-2 px-4 border-b-4 border-primary-orange hover:border-secondary-orange2 rounded">
                        Sign Out
                    </button>
                </div>
                <div className="w-full overflow-x-scroll">
                    <table className="w-full text-sm text-left text-secondary-orange3 overflow-x-scroll">
                        <thead className="text-xs uppercase bg-primary-orange text-white w-full flex overflow-hidden">
                        <tr className="flex w-full">
                            {columnHeadersFieldMap.map(header => {
                                return (
                                    <th scope="col" className="px-6 py-3 text-white w-90 w-full text-center flex">
                                        {header[0]}
                                    </th>
                                )
                            })
                            }
                        </tr>
                        </thead>
                        <tbody className="overflow-y-scroll creator-table-body flex flex-col w-full overflow-x-hidden text-center">
                        {creators.map(creator => {
                            if (creator.creatorid === "") {
                                return null;
                            }
                            return (
                                <tr key={creator.creatorname} className="border-b bg-secondary-grey3 border-primary-orange flex w-full">
                                    {columnHeadersFieldMap.map(header => {
                                        return renderCreatorColunmField(creator, header[1])
                                    })}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                <CSVLink filename={"INTERNAL-creator-list.csv"} data={creators.map(creator => {
                    return {
                        "Creator name (online handle)": creator.creatorname,
                        "Creator real name": creator.creatorrealname,
                        "Creator preferred name": creator.creatorpreferredname,
                        "Creator contact": creator.creatorcontact,
                        "Management contact": creator.managementcontact,
                        "YouTube": creator.youtube,
                        "VR30": creator.vr30,
                        "Twitch": creator.twitch,
                        "Avg. concurrents": creator.avgconcurrents,
                        "Twitter": creator.twitter,
                        "Twitter Reach": creator.twitterreach,
                        "Instagram": creator.instagram,
                        "Instagram Reach": creator.instagramreach,
                        "TikTok": creator.tiktok,
                        "TikTok Reach": creator.tiktokreach,
                        "Creator location": creator.creatorlocation,
                        "Note (free text field)": creator.note,
                    }
                })}>
                    <button
                        className="bg-secondary-grey3 hover:bg-secondary-orange3 text-white font-bold py-2 px-4 border-b-4 border-primary-orange hover:border-secondary-orange2 rounded m-5">
                        Export {creators.length} creators (internal)
                    </button>
                </CSVLink>
                <CSVLink filename={"external-creator-list.csv"} data={creators.map(creator => {
                    return {
                        "Creator name (online handle)": creator.creatorname,
                        "YouTube": creator.youtube,
                        "VR30": creator.vr30,
                        "Twitch": creator.twitch,
                        "Avg. concurrents": creator.avgconcurrents,
                        "Twitter": creator.twitter,
                        "Twitter Reach": creator.twitterreach,
                        "Instagram": creator.instagram,
                        "Instagram Reach": creator.instagramreach,
                        "TikTok": creator.tiktok,
                        "TikTok Reach": creator.tiktokreach,
                        "Creator location": creator.creatorlocation,
                        "Note (free text field)": creator.note,
                    }
                })}>
                    <button
                        className="bg-secondary-grey3 hover:bg-secondary-orange3 text-white font-bold py-2 px-4 border-b-4 border-primary-orange hover:border-secondary-orange2 rounded m-5">
                        Export {creators.length} creators (external)
                    </button>
                </CSVLink>
            </div>
            <img src="FF_Logo_White_RGB_128.png" alt="Fourth Floor Creative Logo" className="bottom-0 right-0 p-5 absolute"/>
        </div>
    );
}

export default List;