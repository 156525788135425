import './App.css'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    useLocation
} from "react-router-dom";
import { AuthContextProvider, useAuth } from "./firebase/firebase";
import List from "./pages/list/list"
import Login from "./pages/login/login"

export default function App() {

    return (
        <AuthContextProvider>
            <Router>
                <Routes>
                    <Route path="/" element={
                        <RequireAuth redirectTo="/login">
                            <List/>
                        </RequireAuth>
                    }/>
                    <Route path="/login" element={<Login/>}/>
                </Routes>
            </Router>
        </AuthContextProvider>
    );
}

const RequireAuth = ({ children, redirectTo }) => {
    const auth = useAuth();
    const location = useLocation();

    if (auth.loading) { return (
        <div className="grid place-items-center h-screen font-body">
            <div className="text-white font-bold bg-secondary-grey2 border-secondary-grey2 border-secondary-grey2 rounded m-5 text-center w-80 outline outline-primary-orange">
                <div className="m-6">Loading...</div>
            </div>
        </div>
    ) }

    if (!auth.user) {
        return <Navigate to={redirectTo} state={{ from: location }} replace/>
    }

    return children;
}