import * as React from "react";
import { initializeApp } from 'firebase/app'
import { getAuth, signOut } from "@firebase/auth";

let config = {
    apiKey: "AIzaSyBtkczntJvlO_FmeWBERfZoBUiy3doyAqM",
    authDomain: "ffc-campaign-tools.firebaseapp.com",
    projectId: "ffc-campaign-tools",
    storageBucket: "ffc-campaign-tools.appspot.com",
    messagingSenderId: "653223903958",
    appId: "1:653223903958:web:53aa7a1499f4eea1787f77",
    measurementId: "G-CDS232XDVJ"
};

const firebaseApp = initializeApp(config);
export default firebaseApp;


export const AuthContext = React.createContext(null);

export function AuthContextProvider( {children}){
    const auth = getAuth();

    const [user, setUser] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (!user) {
                setLoading(false);
                setUser(user);
                return;
            }
            user.getIdToken().then(token => {
                let jwt = JSON.parse(atob(token.split('.')[1]));
                if (jwt.email.endsWith("@fourthfloorcreative.co")) {
                    setUser(user);
                    setLoading(false);
                } else {
                    setError("You are not a FFC user");
                    setLoading(false);
                    setUser(null);
                    signOut(auth);
                }
            }).catch(err => {
                setError(err);
                setLoading(false);
            });
        }, setError);
        return () => unsubscribe()
    }, [auth])
    const value = {user, loading, error};
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
    return React.useContext(AuthContext);
}