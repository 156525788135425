import axios from 'axios';
// Search takes an array of tags and makes a get request to the creators endpoint
export const searchCreators = (tags, token) => {
    // convert tags array into comma separated list string
    const tagList = tags.join(',');

    // make get request to creators endpoint with tags
    return axios.get(`https://goodlist-service-zg4pp3cawq-nw.a.run.app/v1/search?filter=${tagList}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then(res => res.data);
};